// *** FONT ***//
 
// $font-main: "Open Sans", sans-serif;
$font-main: 'Montserrat', sans-serif;
// $font-secondary: "Comforter Brush", cursive;
$font-secondary: "Corinthia", cursive;
$font-title: "Oswald", sans-serif;

// *** COLOR ***//

$color-background: rgb(255, 255, 255);
$color-main: #000000;
$color-secondary: #f3f3ef;

//***  GENERAL ***//

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;
  color: $color-main;
  user-select: none;
}
body {
  position: relative;
  background-color: $color-background;
  color: $color-main;
  font-family: $font-main;
  height: 100%;
  // overflow-y: hidden;
}

.margin-b-2{
  padding-bottom: 2px;
}

.margin-b-8{
  padding-bottom: 8px;
}


.margin-b-16{
  padding-bottom: 16px;
}

.margin-b-32{
  padding-bottom: 32px;
}


img{
  width: 100%;
}


.desktop{
  @media screen and (max-width: 690px) {
      display: none!important;
  }

}

.mobile{
  @media screen and (min-width: 690px) {
      display: none!important;
  }
}

