

.product-container{
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-bottom: 2px solid #ccc;
  @media screen and (max-width: 690px) {
    padding: 16px;
  }
  
}

figure{
  max-width: 80px;
  margin-right: 10px;
  min-width: 65px;
  @media screen and (max-width: 690px) {
    max-width: 55px;
    min-width: 50px;
  }
}

.productSku{
  width: 7%;
  text-align: center;
}
.product{
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: center;
  @media screen and (max-width: 690px) {
    width: 60%;
  }

  .productInfo{
    // min-width: 210px;
   width: 70%;
   h3{
    @media screen and (max-width: 690px) {
      font-size: .9rem;
    }
   }
   p{
    @media screen and (max-width: 690px) {
      font-size: .8rem;
    }
   }
   .qtyPrice{
    display: flex;
    .qty{
      margin-right: 8px;
     }
   }
  }
}
.productEta{
  min-width: 167px;
  width: 21%;
  text-align: center;
  @media screen and (max-width: 690px) {
    min-width: 114px;
  }
}

.productQty{
  min-width: 82px;
  text-align: center;
}

.productPrice{
  width: auto;
  min-width: 116px;
  text-align: center;
}