

header{
    display: flex;
    justify-content: center;
    position: absolute;
    top: 20px;
    left: 60px;
    @media screen and (max-width: 690px) {
        left: 12px;
        top: 12px;
    }

    
    ul{
        display: flex;
        width: 80px;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 690px) {
            width: 20px;
        }
    }

}
main{
    max-width: 1450px;
    margin: auto;
    padding: 60px;
    margin-top: 30px;
    position:relative;

    @media screen and (max-width: 690px) {
        padding: 12px;
        margin-top: 10px;
    }


    .order{
        .scheduleOrder{
            text-align: center;
            margin-bottom: 40px;
            padding: 20px;
            border: 2px solid #ccc;
            @media screen and (max-width: 690px) {
                margin-bottom: 30px;
                padding: 12px;
            }
            a{
                font-weight: 900;
            }
            .anotation{
                font-size: 12px;
                margin-top: 8px;
                font-style: italic ;
                @media screen and (max-width: 690px) {
                    font-size: 9px;
                }
            }
        }
        .order-status{
            width: 100%;
            margin-bottom: 40px;
            text-align: center;
            padding-bottom: 30px;
            display: inline-block;
            text-transform: uppercase;
            vertical-align: top;
            margin-top: 40px;
            padding: 40px 16px;
            border: 2px solid #ccc;
            @media screen and (max-width: 690px) {
                padding: 20px 8px;
                margin-bottom: 30px;
            }

            .clientTopLeft{
                margin-bottom: 60px;
                @media screen and (max-width: 690px) {
                    margin-bottom: 50px;
                }

                .OrderNumber{
                    font-weight: bold;
                    font-size: 36px;
                    @media screen and (max-width: 690px) {
                        font-size: 26px;
                    }
                }
                .clientTopDate{
                    @media screen and (max-width: 690px) {
                        font-size: 14px;
                    }
                }
            }


            ul{
                padding: 0;
                display: flex;
                text-align: center;
                margin: auto;
                list-style: none;
                gap: 20px;
                text-transform: initial;
                justify-content: center;
                @media screen and (max-width: 690px) {
                    gap: 3px;
                }
            }

            li{
                padding-top: 20px;
                position: relative;
                width: 150px;
                height: 50px;
                margin-top: 0;
                margin-bottom: .6875rem;

                @media screen and (max-width: 690px) {
                    font-size: 12px;
                }
                
            }


            li::before{
                position: absolute;
                content: "";
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #000000;
                top: -20px;
                left: 0;
                right: 0;
                margin: auto;
            }
            li::after{
                content: '';
                position: absolute;
                top: -12px;
                left: 72%;
                width: 70%;
                border-top: 1px dashed #889b88;
                display: block;
                height: 1px;
                @media screen and (max-width: 690px) {
                    left: 67%;
                }
            }
            li:last-child:after{
                display: none;
            }

            .statut-commande::before{
                background-color: black;
            }



        }

        .orderDetailsTitle{
            text-align: center;
            font-weight: bold;
            font-size: 30px;
            text-transform: uppercase;
            margin-bottom: 30px;
            @media screen and (max-width: 690px) {
                font-size: 26px;
                margin-bottom: 20px;
            }
        }

        .orderHeader{
            display: flex;
            justify-content: space-between;
            background-color: $color-secondary;
            padding: 20px 20px;
            color: black;
            text-align: center;
            @media screen and (max-width: 690px) {
                padding: 16px;
            }

            

            p{
                color: black;
                font-weight: bold;
                font-size: 1rem;
                line-height: 1;
                text-transform: uppercase;
                @media screen and (max-width: 690px) {
                    font-size: 0.9rem;
                }
            }
        }

        
    }

    .recapOrder{
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .recapOrderTitle, .subttotal, .discount, .shipping, .tax{
            display: flex;
            justify-content: space-between;
            min-width: 200px;
            // border-bottom: .5px solid #00000024;
            padding: 8px 38px 0px 8px;
            @media screen and (max-width: 690px) {
                padding: 8px 16px 0px 8px;
            }
        }
        .total{
            display: flex;
            font-weight: 700;
            font-size: 25px;
            padding: 8px 38px 8px 8px;
            background-color: $color-secondary;
            width: 100%;
            justify-content: flex-end;
            margin-top: 15px;
            @media screen and (max-width: 690px) {
                padding: 8px 16px 8px 8px;
                font-size: 16px;
            }
        }
    }
}



