.header {
    display: flex;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    padding: 8px;
    background-color: rgba($color: #000000, $alpha: 1);
    justify-content: space-between;
    z-index: 1000;
    height: 85px;
    transition: all 0.3s ease-out;
  
    @media screen and (max-width: 950px) {
      justify-content: center;
    }
  
    @media screen and (max-width: 500px) {
      top: auto;
      bottom: 0;
      height: 70px;
      background-color: rgba($color: #000000, $alpha: 1);
    }
  
    .container_logo {
      margin-left: 22.5px;
    }
    img {
      height: 100%;
    }
  
    nav {
      display: flex;
      align-items: center;
  
      ul {
        display: flex;
        align-items: center;
  
        @media screen and (max-width: 950px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          position: fixed;
          right: 0;
          left: 0;
          top: -100vh;
          width: 100vw;
          min-height: 60px;
          visibility: hidden;
          background-color: black;
          transition: all 0.3s ease-out;
          z-index: -1000;
          overflow-y: scroll;
        }
        @media screen and (max-width: 500px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          position: fixed;
          right: 0;
          left: 0;
          top: +100vh;
          width: 100vw;
          min-height: 60px;
          visibility: hidden;
          background-color: black;
          transition: all 0.3s ease-out;
          z-index: -100;
          overflow-y: scroll;
        }
  
        li {
          padding: 8px;
          margin-left: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 950px) {
            border-top: 1px solid white;
            width: 90%;
            // text-align: center;
            height: 80px;
          }
          @media screen and (max-width: 600px) {
            height: 74px;
          }
  
          .header-activ {
            color: white;
            text-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.3);
          }
        }
  
        li:last-child {
          padding-right: 0;
          @media screen and (max-width: 899px) {
            border-bottom: 1px solid white;
            width: 90%;
            text-align: center;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
  
      .show-bar {
        // min-width: 375px;
        top: 85px;
        visibility: visible;
  
        @media screen and (max-width: 500px) {
          top: 0;
          bottom: 70px;
          // height: 100vh;
          justify-content: flex-end;
          transition: all 0.3s ease-out;
        }
      }
  
      a {
        padding: 4px;
        font: size 11px;
        color: rgba(255, 255, 255, 0.7);
        letter-spacing: 1.5px;
        &:hover {
          color: white;
          text-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.3);
        }
        .fab {
          font-size: 1.5em;
        }
  
        .fa-facebook-square:hover {
          color: #3b5998;
        }
        .fa-instagram:hover {
          // background: linear-gradient(0.48turn, #833ab4, #fcaf45);
          background: linear-gradient(
            0.55turn,
            #515bd4,
            #8134af,
            #dd2a7b,
            #feda77,
            #f58529
          );
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }
        .fa-youtube:hover {
          color: #c4302b;
        }
        .fa-spotify:hover {
          color: #1ed761;
        }
        .fa-soundcloud:hover {
          background: linear-gradient(0.48turn, #ff8800, #ff3300);
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
        }
        .fa-bandcamp:hover {
          color: #629aa9;
        }
      }
      .navbar-burger {
        height: 40px;
        background: transparent;
        border: none;
        width: 40px;
        display: none;
  
        @media screen and (max-width: 950px) {
          display: block;
          position: absolute;
          right: 19px;
  
          &:hover {
            cursor: pointer;
          }
        }
  
        span {
          @media screen and (max-width: 950px) {
            display: block;
            width: 40px;
            height: 4px;
            position: relative;
            border-radius: 4px;
            background: white;
            transition: all 0.3s ease-in-out;
  
            &::after,
            &::before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              height: 4px;
              width: 40px;
              background-color: white;
              border-radius: 4px;
              transition: all 0.3s ease-in-out;
            }
  
            &::before {
              transform: translateY(-12px);
            }
            &::after {
              transform: translateY(12px);
            }
          }
        }
  
        .cross-bar {
          width: 0;
          background: transparent;
          &::before {
            transform: rotate(45deg);
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  
  .header.active {
    background-color: rgba($color: #000000, $alpha: 1);
    height: 70px;
    transition: all 0.3s ease-out;
  
    @media screen and (max-width: 500px) {
      height: 70px;
    }
  }
  
  .header.active .show-bar {
    top: 70px;
    transition: all 0.3s ease-out;
  
    @media screen and (max-width: 500px) {
      top: 0;
      bottom: 70px;
      transition: all 0.3s ease-out;
    }
  }