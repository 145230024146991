.log-container{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 690px) {
    padding: 20px;
  }
}
form {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  width: 300px;

  @media screen and (max-width: 414px) {
    width: 100%;
    // margin: 8 auto;
  }

  label{
    font-weight: bold;
    font-size: 18px;
    width: 100%;
  }

  input {
    margin: 0;
    margin-top: 5px;
    padding: 10px 15px;
    font-size: 1.1rem;
    border-radius: 0px;
    border: 1px solid #c5afaf;
    width: 100%;

    &:focus {
      box-shadow: inset 1px 1px 5px #0000001c;
    }
    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s; 
    }
    
  }
  .borderError{
    border-color: #d9534f;
  }

  .error {
    padding: 5px 0px;
    color: #d9534f;
  }
  .validLS {
    margin-bottom: 20px;
    background: black;
    color: #E4E5E5;
    font-size: 16px;
    font-weight: 200;
    text-transform: uppercase;
    font-style: normal;
    cursor: pointer;
  }
}


